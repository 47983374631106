.signin {
  align-items: center;
  align-self: center;
  text-align: center;
}

.player {
  margin-bottom: 40px;
}

.input_slokken {
  margin-right: 10px;
  height: 40px;
  min-width: 80px;
}

.bold-text {
  font-weight: bold !important;
}

.slokken {
  max-height: 35px;
}

.specificGame {
  margin-bottom: 130px;
}

.no-top-padding {
  padding-top: 0 !important;
}

.playerName {
  padding-top: 1rem;
}

.leaderboard-1 {
  color: gold;
}

.leaderboard-2 {
  color: silver;
}

.leaderboard-3 {
  color: brown;
}

.container {
  margin-top: 10px !important;
}

.no-side-padding {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.add-street {
  margin-left: 10px;
}

.color-red {
  color: red;
  font-weight: bold;
}